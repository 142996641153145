<template>
	<base-container>
		<base-row>
			<base-col md="8" offsetMd="2" lg="6" offsetLg="3" xxl="4" offsetXxl="4">
				<base-font variant="section-header"> Przypomnij hasło</base-font>
				<info-box v-if="!!page.status" :status="page.status.code">
					{{page.status.message}}
				</info-box>
				<form v-if="form" class="form">
					<div class="form-inner-wrapper">
						<input-template wider :input="form.inputs.email" autocomplete="email" />
					</div>

					<button-submit @click="page.remindPassword()" :loading="page.processing" type="secondary" :rightSlot="true" class="btn-wider">
						<base-font color="white" tag="span">{{translations.submitButton}}</base-font>
					</button-submit>
				</form>
			</base-col>
		</base-row>
	</base-container>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'
import { InputTemplate } from '@f/core/forms/inputs'
import InfoBox from '~/authorization/front/components/InfoBox'

export default {
	components: {
		ButtonSubmit,
		InputTemplate,
		InfoBox
	},
	computed: {
		page () {
			return this.$app.page
		},
		form () {
			return this.page.form
		},
		translations () {
			return this.$app.translator.get('forms.remindPassword')
		}
	},
	async mounted () {
		await this.page.createForm()
	}
}
</script>
<style lang="scss" scoped>
.form {
	& :deep(label)  {
		color: $primary;
	}
}
:deep(.btn-wider)  {
	.btn {
		width: 100%;
	}
}
</style>
